import React, { Component } from 'react';
import { Link } from "react-router-dom";
import ContactForm from '../components/ContactForm';

export default class Contact extends Component {
    render() {
        return (
            <section className="hero is-primary is-fullheight">
                <div className="hero-head"></div>
                <div className="hero-body">  
                    <div>
                        <p className="title">
                            Contact us
                        </p>
                        <p className="subtitle">
                            Any questions? Please feel free to contact us!
                        </p>
                        <ContactForm />
                    </div>                        
                </div>
                <div className="hero-foot">
                    <div className="container has-text-centered">
                        <Link to="/">inciment.com</Link> by cloudCode | Questions?
                    </div>
                </div>              
            </section>    
        );
    }
}