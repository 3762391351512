import './App.scss';
import Logo from './logo.png';
import { Link } from "react-router-dom";

function App() {
  return (
    <section className="hero is-primary is-fullheight">
      <div className="hero-head">
      </div>
      <div className="hero-body"> 
        <div>
          <p className="title">
          <img 
                src={Logo} 
                alt="Inciment logo" 
                className="frontpagelogo" />
                <br />
            Easy incident management
          </p>
          <p className="subtitle">
            The last thing you need during an incident is a system that drains your teams resources with unecessary complexity.
          </p>
        </div>
      </div>
      <div className="hero-foot">
        <div className="container has-text-centered">
          inciment.com by cloudCode | <Link to="/contact">Questions?</Link>
        </div>
      </div>
    </section>  
  );
}

export default App;
