import React, { useState } from "react";
import { useForm } from "react-hook-form";

import HCaptcha from '@hcaptcha/react-hcaptcha';

import formData from "form-data";
import Mailgun from "mailgun.js";


export default function ContactForm() { 
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ mode: "onTouched", criteriaMode: "all" });

    // hCaptcha
    const [isHcaptchaVerified, setIsHcaptchaVerified] = useState(null);
    const handleVerificationSuccess = (token, ekey) => {
        setIsHcaptchaVerified(true);
    };

    // MG
    const [isSent, setIsSent] = useState(false);
    const mailgun = new Mailgun(formData);
    const mg = mailgun.client({username: 'api', key: process.env.REACT_APP_MG_API_KEY, url: 'https://api.eu.mailgun.net'});

    const onSubmit = (formValues, e) => {
        mg.messages.create('mg.inciment.com', {
            from: 'Inciment.com <website@mg.inciment.com>',
            to: ['otterstad@cloudcode.biz'],
            subject: 'Inciment.com contact form from ' + formValues.fullname + '( '+ formValues.emailaddress +')',
            'h:Reply-To': formValues.emailaddress,
            text: formValues.message,
            html: formValues.message
        })
        .then(msg => {
            e.target.reset();
            setIsSent(true);
            setTimeout(() => {
                setIsSent(false);
            }, 5000);
        }) // logs response data
        .catch(); // logs any error        
    };      

    const renderIcon = (iconName, direction) => {
        return (
            <span className={`icon is-small is-${direction}`}>
                <i className={iconName} />
            </span>
        );
    }; 
    
    const validationSign = (errors) => {
        if (!errors) {
          return null;
        } else {
          return renderIcon("fas fa-exclamation", "right");
        }
      };
      
    return (
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="field">
                <label className="label">
                Your name:
                </label>    
                <div className="control has-icons-right">
                    <input 
                        {...register("fullname", { required: true })}
                        className={`input ${errors.fullname ? "is-danger" : "is-info"}`}                    
                        type="text" 
                        placeholder="Your name" />
                    {validationSign(errors.fullname)}
                </div> 
                <p className="help is-danger">
                    {errors.fullname?.type === "required" && "Your name is required"}
                </p>                       
            </div>            
            <div className="field">
                <label className="label">Email:</label>
                <div className="control has-icons-right">
                    <input 
                        {...register("emailaddress", { 
                            required: true,
                            pattern:
                            // eslint-disable-next-line no-useless-escape
                            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                            minLength: 8, 
                        })}
                        className={`input ${errors.emailaddress ? "is-danger" : "is-info"}`}    
                        type="email" 
                        placeholder="Email" />
                    {validationSign(errors.emailaddress)}                        
                </div>
                <p className="help is-danger">
                    {errors.emailaddress?.type === "required" &&
                    "Email is required"}
                    {errors.emailaddress?.type === "pattern" &&
                    "Please input correct email format"}
                    {errors.emailaddress?.type === "minLength" &&
                    "Email address is too short"}
                </p>                    
            </div>
            <div className="field">
            <label className="label">Message:</label>
                <div className="control has-icons-right">
                    <textarea 
                        {...register("message", { required: true })}
                        className={`textarea ${errors.message ? "is-danger" : "is-info"}`}  
                        placeholder="Your message"></textarea>
                    {validationSign(errors.message)}                         
                </div>
                <p className="help is-danger">
                    {errors.message?.type === "required" && "You must fill out a message to us"}
                </p>                  
            </div>
            <div className="field">
                <HCaptcha
                    sitekey="4fbc11b2-190b-4c2c-97a4-47f821db0608"
                    onVerify={(token,ekey) => handleVerificationSuccess(token, ekey)}
                />                              
            </div>
            <div className="field is-grouped">
                <button disabled={!isHcaptchaVerified} className="button is-link">Send question</button>
            </div>
            <div className={`box has-background-info has-text-light p-3 ${isSent ? "": "is-hidden"}`}>
                Your question is sent. We will respond as soon as possible.
            </div>
        </form>             
    );
}